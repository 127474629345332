html,
body,
div#__next {
  height: 100%;
}

/* Hide google recaptcha badge */
.grecaptcha-badge {
  visibility: hidden;
}

 .swiper-button-next {
  background-image: url(https://res.cloudinary.com/hfq3eckim/image/upload/v1650354224/Portfolio/arrow_right_bmfge4.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}



.swiper-button-prev {
  background-image: url(https://res.cloudinary.com/hfq3eckim/image/upload/v1650354224/Portfolio/arrow_left_x7lnwl.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after, .swiper-button-next::after {
  display: none;
}

.swiper-button-disabled {
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}

.swiper-pagination > .swiper-pagination-bullet-active {
  background-color: green;
}

.swiper-pagination {
  bottom: -10px;
}
